// @flow

import React from 'react'

import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  FileInput,
  ImageField,
  DateInput,
} from 'react-admin'
import { GENERAL_STATES, EVENT_TYPES } from '../../constants'

const EventEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <DateInput label="Datum" source="date_start" />

      <SelectArrayInput choices={EVENT_TYPES} source="types" />
      <TextInput multiline source="description" />
      <TextInput source="link" />

      <FileInput accept="image/*" label="Event Bild" source="image">
        <ImageField source="url" title="title" />
      </FileInput>

      <SelectInput choices={GENERAL_STATES} label="Status" source="state" />

      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Edit>
)

export default EventEdit
