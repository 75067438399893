import React from 'react'
import { ReferenceField } from 'react-admin'

import { RESOURCE_NAMES } from '../../constants'

import FullNameField from './full-name-field'

const UserReferenceField = props => (
  <ReferenceField reference={RESOURCE_NAMES.USERS} source="id" {...props}>
    <FullNameField />
  </ReferenceField>
)

UserReferenceField.defaultProps = {
  source: 'id',
  label: 'User',
  addLabel: true,
}

export default UserReferenceField
