import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { GET_LIST, Responsive, withDataProvider } from 'react-admin'

import Welcome from './welcome'
import NewMembers from './new-members'
import NewJobs from './new-jobs'
import UpcomingEvent from './upcoming-events'

class Dashboard extends PureComponent {
  state = {}

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    // this.fetchOrders();
    // this.fetchReviews();
    this.fetchNewMembers()
  }

  async fetchNewMembers() {
    const { dataProvider } = this.props

    const aMonthAgo = new Date()
    aMonthAgo.setDate(aMonthAgo.getDate() - 30)

    const { data: newMembers } = await dataProvider(GET_LIST, 'users', {
      filter: {
        collectionQuery: collection =>
          collection.where('directory_show', '==', true),
        // first_seen_gte: aMonthAgo.toISOString(),
      },
      sort: { field: 'frist_name', order: 'DESC' },
      pagination: { page: 1, perPage: 100 },
    })

    this.setState({
      newMembers,
      nbNewMembers: newMembers.reduce(nb => ++nb, 0),
    })
  }

  render() {
    const {
      newMembers,
      nbNewMembers,
      newJobs,
      nbNewJobs,
      upcompingEvents,
      nbUpcomingEvents,
    } = this.state

    return (
      <Responsive
        medium={
          <div style={styles.flex}>
            <div style={styles.leftCol}>
              <div style={styles.singleCol}>
                <Welcome />
              </div>
              <div style={styles.flex}>
                <NewJobs jobs={newJobs} nb={nbNewJobs} />
                <UpcomingEvent jobs={upcompingEvents} nb={nbUpcomingEvents} />
              </div>

              {/* <div style={styles.singleCol}>leftColsingle</div> */}
            </div>

            <div style={styles.rightCol}>
              <div style={styles.flex}>
                <NewMembers members={newMembers} nb={nbNewMembers} />
              </div>
            </div>
          </div>
        }
        small={
          <div style={styles.flexColumn}>
            <div style={styles.singleCol}>
              <Welcome />
            </div>
            <div style={styles.singleCol}>
              <NewMembers members={newMembers} nb={nbNewMembers} />
            </div>

            <div style={styles.flex}>
              <NewJobs jobs={newJobs} nb={nbNewJobs} />
              <UpcomingEvent jobs={upcompingEvents} nb={nbUpcomingEvents} />
            </div>
          </div>
        }
        xsmall={
          <div>
            <div style={styles.flexColumn}>
              <div style={{ marginBottom: '2em' }}>
                <Welcome />
              </div>
              <div style={styles.flex}>
                <NewJobs jobs={newJobs} nb={nbNewJobs} />
                <UpcomingEvent jobs={upcompingEvents} nb={nbUpcomingEvents} />
              </div>
              <div style={styles.singleCol}>
                <NewMembers members={newMembers} nb={nbNewMembers} />
              </div>
            </div>
          </div>
        }
      />
    )
  }
}

const styles = {
  flex: { display: 'flex', flexWrap: 'wrap', marginBottom: '2em' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginBottom: '2em' },
}

export default compose(
  connect(state => ({
    version: state.admin.ui.viewVersion,
  })),
  withDataProvider,
)(Dashboard)
