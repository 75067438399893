// @flow

import React from 'react'
import { List, Datagrid, BooleanField } from 'react-admin'

import { UserReferenceField } from '../users'

const RoleList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <UserReferenceField />

        <BooleanField source="role_member" />
        <BooleanField source="role_team" />
        <BooleanField source="role_moderator" />
        <BooleanField source="role_admin" />
      </Datagrid>
    </List>
  )
}

export default RoleList
