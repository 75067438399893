import React from 'react'
import { Link } from 'react-router-dom'
import { translate } from 'react-admin'

import compose from 'recompose/compose'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EventIcon from '@material-ui/icons/Event'
import Divider from '@material-ui/core/Divider'

import { RESOURCE_NAMES } from '../../constants'

import CardIcon from './card-icon'

const UpcomingEvents = ({ events = [], nb, translate, classes }) => (
  <div className={classes.main}>
    <CardIcon Icon={EventIcon} bgColor="#4caf50" />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {translate('pos.dashboard.new_jobs')}
      </Typography>
      <Typography className={classes.value} component="h2" variant="headline">
        {nb}
      </Typography>
      <Divider />
      <List>
        {events.map(record => (
          <ListItem
            button
            component={Link}
            key={record.id}
            to={`${RESOURCE_NAMES.EVENTS}/${record.id}`}
          >
            <Avatar
              className={classes.avatar}
              src={`${record.avatar}?size=32x32`}
            />
            <ListItemText
              className={classes.listItemText}
              primary={`${record.name_frist} ${record.name_last}`}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  </div>
)

const styles = theme => ({
  main: {
    flex: '1',
    marginLeft: '1em',
    marginTop: 20,
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    paddingRight: 0,
  },
})

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(UpcomingEvents)
