// @flow

import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  DateInput,
  FileInput,
  ImageField,
} from 'react-admin'
import { GENERAL_STATES, EVENT_TYPES } from '../../constants'

const EventCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <DateInput label="Datum" source="date_start" />

      <SelectArrayInput choices={EVENT_TYPES} label="Eventart" source="types" />
      <TextInput multiline source="description" />
      <TextInput source="link" />

      <FileInput accept="image/*" label="Event Bild" source="image">
        <ImageField source="url" title="title" />
      </FileInput>

      <SelectInput choices={GENERAL_STATES} source="state" />
    </SimpleForm>
  </Create>
)

export default EventCreate
