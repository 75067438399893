// @flow

import React from 'react'

import { List, Datagrid, TextField, UrlField, CloneButton } from 'react-admin'

const BenefitList = ({
  permissions,
  ...props
}: {
  permissions: { role_moderator: boolean },
}) => {
  let filter = {
    collectionQuery: collection => collection.where('state', '==', 'published'),
  }

  // Workaround: Using permissions and altering filter sets resources event prop 'perPage' to 'NaN'
  // and thereby does not show list records on first render
  if (!permissions) return null

  // Remove filter for moderators
  if (permissions.role_moderator) {
    delete filter.collectionQuery
  }

  return (
    <List {...props} filter={filter}>
      <Datagrid rowClick="edit">
        {permissions.role_moderator ? <CloneButton /> : null}
        <TextField source="name" />
        <TextField source="state" />
        <TextField source="description" />
        <UrlField source="link" />

        {/* <TextField source="id" /> */}
      </Datagrid>
    </List>
  )
}

export default BenefitList
