import React from 'react'

import { Edit, SimpleForm, BooleanInput, BooleanField } from 'react-admin'

import { UserReferenceField } from '../users'

const RoleEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <UserReferenceField />

      <BooleanField source="role_member" />
      <BooleanInput source="role_team" />
      <BooleanInput source="role_moderator" />
      <BooleanInput source="role_admin" />

      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Edit>
)

export default RoleEdit
