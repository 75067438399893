import React from 'react'
import { withRouter, Redirect } from 'react-router'

import StripePayment from './stripe/stripe-payment'

class Payment extends React.PureComponent {
  render() {
    // const { loaded, loading, saving } = this.state
    const urlState =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.state

    // Get URL Params
    const params = new URLSearchParams(window.location.search)
    const email = params.get('email')
    const reference = params.get('reference')
    const stripePlan = params.get('stripe_plan')
    const stripeSessionId = params.get('stripe_session_id')
    const livemode = params.get('test') !== 'true'

    console.log('urlState', urlState)

    if (
      // urlState === 'success' ||
      urlState === 'cancel'
    )
      return <span>{urlState}</span>

    if (urlState === 'success') return <Redirect to="/" />

    return (
      <div className={'payment'}>
        <StripePayment
          data={{ email, reference, stripePlan, stripeSessionId }}
          livemode={livemode}
          method={urlState}
        />
      </div>
    )
  }
}

export default withRouter(Payment)
