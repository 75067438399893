// @flow

import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EmailField,
  Filter,
  TextInput,
  SelectField,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const UserFilter = props => {
  return (
    <Filter {...props}>
      <TextInput alwaysOn label="Search" source="q" />
      <TextInput label="Firstname" source="email" />
    </Filter>
  )
}

const styles = { image: { maxHeight: '2rem' } }

const UsersList = withStyles(styles)(props => (
  <List {...props} filters={<UserFilter />} title="All Members">
    <Datagrid rowClick="edit">
      <TextField source="name_first" />
      <TextField source="name_last" />
      <TextField label="Company" source="name_company" />

      <EmailField source="email" />

      <BooleanField label="Active (bezahlt)" source="_meta_membership_active" />

      <SelectField
        choices={[
          { id: 'person', name: 'Person' },
          { id: 'company', name: 'Firma' },
        ]}
        label="Type"
        source="_meta_membership_type"
      />

      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
))

export default UsersList
