import React, { Component } from 'react'
import { Admin } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  // FirebaseRealTimeSaga,
} from 'react-admin-firebase'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { createMuiTheme } from '@material-ui/core/styles'
// import { orange, cyan } from '@material-ui/core/colors/'

import i18nProvider from './i18n'
import { config as firebaseConfig } from './lib/firebase/config'

import Dashboard from './components/dashboard/'
import Payment from './components/other/payment/payment'
import CustomLoginPage from './components/auth/custom-login-page'

import resourceManager from './ra-resource-manager'

const options = {
  logging: true,
  authMode: ['email-link'],
}

const dataProvider = FirebaseDataProvider(firebaseConfig, options)
const authProvider = FirebaseAuthProvider(firebaseConfig, options)
// const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);

const i18nProviderWrapped = polyglotI18nProvider(i18nProvider, 'en')

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route component={Payment} path={'/pay/:state*'} />
          <Route
            exact
            path="/"
            render={() => (
              <div className="App">
                <Admin
                  authProvider={authProvider}
                  // dashboard={Dashboard}
                  dataProvider={dataProvider}
                  i18nProvider={i18nProviderWrapped}
                  loginPage={CustomLoginPage}
                  // theme={theme} // seams to be broken after update auf RA v3
                  title="ECC-Memberarea"
                >
                  {permissions => resourceManager(permissions)}
                </Admin>
              </div>
            )}
          />
        </Switch>
      </Router>
    )
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e54320',
    },
    secondary: {
      main: '#1c1f3e',
    },
    // // primary1Color: ,
    // accent1Color: orange[700],
    // type: 'dark', // Switching the dark mode on is a single property value change.
  },
})

export default App
