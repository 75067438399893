// @flow

import React from 'react'

import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin'

const MembershipCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <NumberInput source="price" />
      <TextInput
        label="Registration Form Name"
        source="_meta_d_registration_reference"
      />
    </SimpleForm>
  </Create>
)

export default MembershipCreate
