// @flow

import React from 'react'
import { List, Datagrid, TextField, UrlField, CloneButton } from 'react-admin'

import { ArrayChipField } from '../../common'

const CompanyList = props => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name_company" />
        <UrlField source="link" />
        <TextField source="description_short" />
        <ArrayChipField source="categories" />
        {/* <TextField source="description" /> */}

        {/* <TextField source="id" /> */}
        <CloneButton />
      </Datagrid>
    </List>
  )
}

export default CompanyList
