import React from 'react'
import PropTypes from 'prop-types'

import { NumberField } from 'react-admin'

const ExtendedNumberField = ({
  source,
  record = {},
  decimalConversion = 1,
  ...rest
}) => {
  const newRecord = { ...record }

  if (typeof newRecord[source] === 'number') {
    newRecord[source] = newRecord[source] / decimalConversion
  }

  return <NumberField record={newRecord} source={source} {...rest} />
}

ExtendedNumberField.propTypes = {
  decimalConversion: PropTypes.number,
  label: PropTypes.string,
  record: PropTypes.object,
  rest: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default ExtendedNumberField
