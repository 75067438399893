import React from 'react'

import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin'

const RoleCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput label="User" reference="users" source="id">
        <AutocompleteInput
          optionText={choice => `${choice.name_first} ${choice.name_last}`}
          source="id"
        />
      </ReferenceInput>
      <BooleanInput source="role_team" />
      <BooleanInput source="role_moderator" />
      <BooleanInput source="role_admin" />

      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Create>
)

export default RoleCreate
