// @flow

import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  // UrlField,
  CloneButton,
  SelectField,
  ReferenceField,
} from 'react-admin'
import { ArrayChipField, Pagination } from '../common'
import { GENERAL_STATES, JOB_KIND } from '../../constants'

const JobList = ({
  permissions,
  ...props
}: {
  permissions: { role_moderator: boolean },
}) => {
  let filter = {
    collectionQuery: collection => collection.where('state', '==', 'published'),
  }

  // Workaround: Using permissions and altering filter sets resources event prop 'perPage' to 'NaN'
  // and thereby does not show list records on first render
  if (!permissions) return null

  // Remove filter for moderators
  if (permissions.role_moderator) {
    delete filter.collectionQuery
  }

  return (
    <List {...props} filter={filter} pagination={<Pagination />}>
      <Datagrid rowClick="edit">
        <SelectField choices={GENERAL_STATES} source="state" />
        <SelectField choices={JOB_KIND} source="kind" />

        <ArrayChipField source="categories" />
        <ArrayChipField source="types" />

        <TextField source="name" />

        <ReferenceField
          label="Company"
          reference="ecc_directory_companies"
          source="user_id"
          title="Company"
        >
          <TextField source="name_company" />
        </ReferenceField>

        {/* <UrlField source="link" /> */}
        <CloneButton />

        {/* <TextField source="id" /> */}
      </Datagrid>
    </List>
  )
}

export default JobList
