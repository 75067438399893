// @flow

import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  UrlField,
  SelectField,
  DateField,
} from 'react-admin'

const MagazinList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <SelectField
        choices={[
          { id: 'draft', name: 'Entwurf' },
          { id: 'review', name: 'Prüfung' },
          { id: 'publish', name: 'Veröffentlicht' },
        ]}
        source="status"
      />
      <TextField source="title" />

      <UrlField source="link" />
      <DateField label="Veröffentlicht" source="date" />

      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
)

export default MagazinList
