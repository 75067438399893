// @flow

import React from 'react'
import { List, Datagrid, TextField, EmailField } from 'react-admin'

const PersonList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name_first" />
      <TextField source="name_last" />
      <EmailField source="email" />

      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
)

export default PersonList
