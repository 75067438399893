import React, { Component } from 'react'
import {
  IbanElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements'

import './../payment.css'

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }
}

class _IbanForm extends React.Component {
  state = {
    errorMessage: '',
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message })
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()

    if (this.props.stripe) {
      this.props.stripe
        .createSource({
          type: 'sepa_debit',
          currency: 'eur',
          owner: {
            name: ev.target.name.value,
            email: ev.target.email.value,
          },
          mandate: {
            notification_method: 'email',
          },
        })
        .then(result => this.props.handleResult(this.props.stripe, result))
    } else {
      console.log("Stripe.js hasn't loaded yet.")
    }
  }

  render() {
    const { address } = this.props

    return (
      <div className="stripe-payment-sepa">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="split-form">
            <label>
              Name
              <input name="name" placeholder="Jane Doe" required type="text" />
            </label>
            <label>
              Email
              <input
                disabled
                name="email"
                placeholder="jane.doe@example.com"
                required
                type="email"
                value={address.email}
              />
            </label>
          </div>
          <label>
            IBAN
            <IbanElement
              onChange={this.handleChange}
              supportedCountries={['SEPA']}
              {...createOptions()}
            />
          </label>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <button>Pay</button>
          {/* Display mandate acceptance text. */}
          <div id="mandate-acceptance">
            By providing your IBAN and confirming this payment, you are
            authorizing "Entrepreneurs Club Cologne e.V." and Stripe, our
            payment service provider, to send instructions to your bank to debit
            your account and your bank to debit your account in accordance with
            those instructions. You are entitled to a refund from your bank
            under the terms and conditions of your agreement with your bank. A
            refund must be claimed within 8 weeks starting from the date on
            which your account was debited.
          </div>
        </form>
      </div>
    )
  }
}

const IbanForm = injectStripe(_IbanForm)

class StripePaymentIban extends Component {
  render() {
    return (
      <StripeProvider apiKey={this.props.apiKey}>
        <Elements>
          <IbanForm
            address={this.props.address}
            handleResult={this.props.handleResult}
          />
        </Elements>
      </StripeProvider>
    )
  }
}

export default StripePaymentIban
