import React from 'react'

import {
  List,
  Datagrid,
  UrlField,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  Create,
  TextInput,
} from 'react-admin'

export const ShortenerList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Shortname" source="id" />
      <UrlField source="target" />
      <TextField label="Description" source="description" />
      <EditButton />
    </Datagrid>
  </List>
)

export const ShortenerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Shortname" resettable source="id" />
      <TextInput label="Ziel URL" resettable source="target" />
    </SimpleForm>
  </Create>
)

export const ShortenerEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Shortname" source="id" />
      <TextInput label="Ziel URL" resettable source="target" type="url" />
    </SimpleForm>
  </Edit>
)
