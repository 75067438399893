import React from 'react'
import { Resource, EditGuesser } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHandsHeart,
  faEuroSign,
  faUsers,
  faNewspaper,
  faUserEdit,
  faHandshake,
  faUserFriends,
  faBuilding,
  faLink,
  faCalendarDay,
  faTrophyAlt,
  faCogs,
} from '@fortawesome/pro-solid-svg-icons'

import { RESOURCE_NAMES, USER_ROLES } from './constants'

import { BenefitList, BenefitEdit, BenefitCreate } from './components/benefits'
import {
  UserList,
  UserEdit,
  // UserCreate
} from './components/users'
import { RoleList, RoleEdit, RoleCreate } from './components/roles'
import { ConfigList, ConfigEdit } from './components/config'
import {
  ShortenerList,
  ShortenerEdit,
  ShortenerCreate,
} from './components/shortener'
import { JobList, JobEdit, JobCreate } from './components/jobs'
import { EventList, EventEdit, EventCreate } from './components/events'
import {
  MembershipList,
  MembershipCreate,
  MembershipEdit,
} from './components/membership'
import { PaymentList } from './components/payments'
import { PersonList } from './components/directory/persons/'
import {
  CompanyList,
  // CompanyCreate
} from './components/directory/companies/'
import { MagazinList } from './components/magazin/'

const hasModeratorRole = permission => permission[USER_ROLES.MODERATOR]
const hasAdminRoleRole = permission =>
  permission[USER_ROLES.ADMIN] || permission[USER_ROLES.MODERATOR]

export default permissions => {
  const resources = []

  if (permissions[USER_ROLES.MEMBER]) {
    resources.push(memberResources(permissions))
  }

  if (permissions[USER_ROLES.TEAM]) {
    resources.push(memberResources(permissions), teamResources(permissions))
  }

  if (permissions[USER_ROLES.MODERATOR]) {
    resources.push(
      memberResources(permissions),
      teamResources(permissions),
      moderatorResources(permissions),
    )
  }

  if (permissions[USER_ROLES.ADMIN]) {
    resources.push(
      memberResources(permissions),
      teamResources(permissions),
      moderatorResources(permissions),
      adminResources(permissions),
    )
  }

  // If resources is empty, might be a non active user trying to login to pay/activate membership
  if (resources.length < 1) {
    resources.push(notActiveMemberResouces(permissions))
  }

  return resources
}

const notActiveMemberResouces = permissions => [
  // JOBS BOARD
  <Resource
    create={hasModeratorRole(permissions) ? JobCreate : null}
    edit={hasModeratorRole(permissions) ? JobEdit : null}
    icon={() => <FontAwesomeIcon fixedWidth icon={faHandshake} />}
    key={RESOURCE_NAMES.JOBS}
    list={JobList}
    name={RESOURCE_NAMES.JOBS}
    options={{ label: 'Jobs' }}
  />,
]

const memberResources = permissions => [
  // DIRECTORY PERSONS
  <Resource
    icon={() => <FontAwesomeIcon fixedWidth icon={faUserFriends} />}
    key={RESOURCE_NAMES.DIRECTORY_PERSONS}
    list={PersonList}
    name={RESOURCE_NAMES.DIRECTORY_PERSONS}
    options={{ label: 'DIRECTORY: Members' }}
  />,
  // DIRECTORY COMPANIES / PARTNER LIST
  <Resource
    // create={CompanyCreate} // Should not be created! Done by a user of type "company" configurating its directory-fields
    icon={() => <FontAwesomeIcon fixedWidth icon={faBuilding} />}
    key={RESOURCE_NAMES.DIRECTORY_COMPANIES}
    list={CompanyList}
    name={RESOURCE_NAMES.DIRECTORY_COMPANIES}
    options={{ label: 'DIRECTORY: Companies' }}
  />,
  // BENEFITS
  <Resource
    create={hasModeratorRole(permissions) ? BenefitCreate : null}
    edit={hasModeratorRole(permissions) ? BenefitEdit : null}
    icon={() => <FontAwesomeIcon fixedWidth icon={faHandsHeart} />}
    key={RESOURCE_NAMES.BENEFITS}
    list={BenefitList}
    name={RESOURCE_NAMES.BENEFITS}
    options={{ label: 'Benefits' }}
  />,
  notActiveMemberResouces(permissions),
]

const teamResources = permissions => [
  // SHORTNER
  <Resource
    create={ShortenerCreate}
    edit={ShortenerEdit}
    icon={() => <FontAwesomeIcon fixedWidth icon={faLink} />}
    key={RESOURCE_NAMES.SHORTENER}
    list={ShortenerList}
    name={RESOURCE_NAMES.SHORTENER}
    options={{ label: 'Shortener' }}
  />,
  // Magazin
  <Resource
    icon={() => <FontAwesomeIcon fixedWidth icon={faNewspaper} />}
    key={RESOURCE_NAMES.MAGAZIN}
    list={MagazinList}
    name={RESOURCE_NAMES.MAGAZIN}
    options={{ label: 'Magazin' }}
  />,
  // EVENTS
  <Resource
    create={hasModeratorRole(permissions) ? EventCreate : null}
    edit={hasModeratorRole(permissions) ? EventEdit : null}
    icon={() => <FontAwesomeIcon fixedWidth icon={faCalendarDay} />}
    key={RESOURCE_NAMES.EVENTS}
    list={EventList}
    name={RESOURCE_NAMES.EVENTS}
    options={{ label: 'Events' }}
  />,
]

const moderatorResources = permissions => [
  // MEMBER
  <Resource
    // create={hasAdminRoleRole(permissions) ? UserCreate : null}
    edit={hasAdminRoleRole(permissions) ? UserEdit : null}
    icon={() => <FontAwesomeIcon fixedWidth icon={faUsers} />}
    key={RESOURCE_NAMES.USERS}
    list={UserList}
    name={RESOURCE_NAMES.USERS}
    options={{ label: 'Members' }}
  />,
  // Membership
  <Resource
    create={MembershipCreate}
    edit={MembershipEdit}
    icon={() => <FontAwesomeIcon fixedWidth icon={faTrophyAlt} />}
    key={RESOURCE_NAMES.MEMBERSHIP}
    list={MembershipList}
    name={RESOURCE_NAMES.MEMBERSHIP}
    options={{ label: 'Memberships' }}
  />,
  // Payments
  <Resource
    icon={() => <FontAwesomeIcon fixedWidth icon={faEuroSign} />}
    key={RESOURCE_NAMES.PAYMENTS}
    list={PaymentList}
    name={RESOURCE_NAMES.PAYMENTS}
    options={{ label: 'Payments' }}
  />,
]

const adminResources = () => [
  // Global Config
  <Resource
    edit={ConfigEdit}
    icon={() => <FontAwesomeIcon fixedWidth icon={faCogs} />}
    key={RESOURCE_NAMES.CONFIG_GLOBAL}
    list={ConfigList}
    name={RESOURCE_NAMES.CONFIG_GLOBAL}
    options={{ label: 'Config' }}
  />,
  // Roles
  <Resource
    create={RoleCreate}
    edit={RoleEdit}
    icon={() => <FontAwesomeIcon fixedWidth icon={faUserEdit} />}
    key={RESOURCE_NAMES.CONFIG_ROLES}
    list={RoleList}
    name={RESOURCE_NAMES.CONFIG_ROLES}
    options={{ label: 'Roles' }}
  />,
]
