// @flow

import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
} from 'react-admin'
import {
  GENERAL_CATEGORIES,
  BENEFIT_TYPES,
  GENERAL_STATES,
} from '../../constants'

const BenefitCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="link" />

      <SelectArrayInput choices={GENERAL_CATEGORIES} source="categories" />
      <SelectArrayInput choices={BENEFIT_TYPES} source="types" />

      <SelectInput choices={GENERAL_STATES} source="state" />

      <TextInput source="createdBy" />

      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Create>
)

export default BenefitCreate
