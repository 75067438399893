// @flow

import React from 'react'
// import AvatarField from './AvatarField';
import pure from 'recompose/pure'

const FullNameField = ({ record = {} }: { record: object, size: number }) => (
  <div
    label="User"
    style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}
  >
    {/* <AvatarField record={record} size={size} /> */}
    &nbsp;{record.name_last} {record.name_frist}
  </div>
)

const PureFullNameField = pure(FullNameField)

PureFullNameField.defaultProps = {
  source: 'id',
  label: 'resources.customers.fields.name',
}

export default PureFullNameField
