const RESOURCE_NAMES = {
  USERS: 'users',
  BENEFITS: 'ecc_feature_benefits',
  JOBS: 'ecc_feature_jobs',
  // BOARD_COFOUNDER: 'ecc_features_cofounder',
  EVENTS: 'ecc_feature_events',
  PAYMENTS: 'ecc_feature_payments',
  MAGAZIN: 'ecc_feature_magazin',
  MEMBERSHIP: 'ecc_feature_membership',
  CRM_CONTACTS: 'ecc_crm_contacts',
  CRM_COMPANIES: 'ecc_crm_companies',
  CONFIG_ROLES: 'ecc_config_roles',
  CONFIG_GLOBAL: 'ecc_config',
  SHORTENER: 'ecc_feature_shortener',
  DIRECTORY_PERSONS: 'ecc_directory_persons',
  DIRECTORY_COMPANIES: 'ecc_directory_companies',
}

const USER_ROLES = {
  ADMIN: 'role_admin',
  MODERATOR: 'role_moderator',
  TEAM: 'role_team',
  MEMBER: 'role_member',
}

const GENERAL_CATEGORIES = [
  { id: 'software-development', name: 'Software Development' },
  { id: 'business-development', name: 'Business Development' },
  { id: 'sales', name: 'Sales' },
  { id: 'marketing', name: 'Marketing' },
  { id: 'people', name: 'People/HR' },
  { id: 'consulting', name: 'Beratung' },
  { id: 'design', name: 'Design' },
  { id: 'law', name: 'Recht' },
]
const GENERAL_STATES = [
  { id: 'draft', name: 'Entwurf' },
  { id: 'review', name: 'Prüfung' },
  { id: 'published', name: 'Veröffentlicht' },
  { id: 'deactivated', name: 'Deaktiviert' },
  { id: 'intern', name: 'Team' },
]

const JOB_TYPES = [
  { id: 'internship', name: 'Praktikum' },
  { id: 'full-time', name: 'Vollzeit' },
  { id: 'working-student', name: 'Werkstudent' },
  { id: 'part-time', name: 'Teilzeit' },
  { id: 'volunteering', name: 'Ehrenamtlich' },
]

const JOB_KIND = [
  { id: 'regular', name: 'Regular' },
  { id: 'cofounder', name: 'Cofounder Search' },
]

const BENEFIT_TYPES = [
  { id: 'event-discount', name: 'Event Rabatt' },
  { id: 'event-free', name: 'Event Kostenlos' },
  { id: 'consulting', name: 'Beratung' },
  { id: 'product', name: 'Produkt' },
  { id: 'tool', name: 'Tool' },
  { id: 'membership', name: 'Mitgliedschaft' },
]

const EVENT_TYPES = [
  { id: 'gruenderabend', name: 'Gründerabend' },
  { id: 'pitch-night', name: 'Pitch Night' },
  { id: 'workshop', name: 'Workshop' },
  { id: 'presentation', name: 'Vortrag' },
  { id: 'partner-event', name: 'Partner-Event' },
  { id: 'breakfast', name: 'Gründerfrühstück' },
  { id: 'fun-event', name: 'Fun Event' },
  { id: 'party', name: 'Party' },
  { id: 'conference', name: 'Konferenz' },
  { id: 'breakfast', name: 'Gründerfrühstück' },
  { id: 'team-event', name: 'Team Event' },
  { id: 'welcome-event', name: 'Welcome Event' },
]

export {
  RESOURCE_NAMES,
  USER_ROLES,
  GENERAL_CATEGORIES,
  JOB_TYPES,
  JOB_KIND,
  GENERAL_STATES,
  BENEFIT_TYPES,
  EVENT_TYPES,
}
