// @flow

import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  UrlField,
  CloneButton,
  SelectField,
  DateField,
} from 'react-admin'
import { ArrayChipField, Pagination } from '../common'
import { GENERAL_STATES } from '../../constants'

const EventList = ({
  permissions,
  ...props
}: {
  permissions: { role_moderator: boolean },
}) => {
  let filter = {
    collectionQuery: collection =>
      collection
        .where('state', '==', 'published')
        .orderBy('date_start', 'desc'),
  }

  // Workaround: Using permissions and altering filter sets resources event prop 'perPage' to 'NaN'
  // and thereby does not show list records on first render
  if (!permissions) return null

  // Remove filter for moderators
  if (permissions.role_moderator) {
    delete filter.collectionQuery
  }

  return (
    <List {...props} filter={filter} pagination={<Pagination />}>
      <Datagrid rowClick="edit">
        <SelectField choices={GENERAL_STATES} source="state" />
        <DateField label="Datum" source="date_start" />

        <TextField source="name" />
        <ArrayChipField source="types" />

        {/* <TextField source="description" /> */}

        <UrlField source="link" />
        <CloneButton />

        {/* <TextField source="id" /> */}
      </Datagrid>
    </List>
  )
}

export default EventList
