// @flow

import React from 'react'
import { List, Datagrid, TextField, BooleanField } from 'react-admin'

const PaymentList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="email_billing" />
      <BooleanField source="paid" />
      <TextField source="refunded" />
      <TextField source="status" />

      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
)

export default PaymentList
