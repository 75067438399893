// @flow

import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'
import { ExtendedNumberField } from '../common'

const MembershipList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ExtendedNumberField
        decimalConversion={100}
        options={{
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'code',
          // minimumFractionDigits: 2,
          // maximumFractionDigits: 3,
          // minimumIntegerDigits: 15,
        }}
        source="price"
      />
      <TextField
        label="Registration Form Name"
        source="_meta_d_registration_reference"
      />
      <TextField
        label="Stripe Payment Plan"
        source="_meta_d_payment_reference"
      />
      <TextField
        label="Stripe Payment Plan (TEST)"
        source="_meta_d_payment_test_reference"
      />

      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
)

export default MembershipList
