// @flow

import React from 'react'
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin'

const JobEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput label="Preis in Cent (20 EUR=2000 Cent)" source="price" />

      <TextInput
        label="Registration Form Name"
        source="_meta_d_registration_reference"
      />
      {/* <TextInput
        disabled
        label="Stripe Payment Plan"
        source="_meta_d_payment_reference"
      />
      <TextInput
        disabled
        label="Stripe Payment Plan (TEST)"
        source="_meta_d_payment_test_reference"
      /> */}
    </SimpleForm>
  </Edit>
)

export default JobEdit
