import React, { cloneElement } from 'react'

import { SingleFieldList, ChipField, ArrayField } from 'react-admin'

const StringToLabelObject = ({ record, children, ...rest }) =>
  cloneElement(children, {
    record: { label: record },
    ...rest,
  })

const ArrayChipField = props => {
  const sourceField = props.source
  const record = props.record

  if (!Array.isArray(record[sourceField])) {
    console.error(
      'ArrayChipField:: record field not an array',
      sourceField,
      record,
    )

    return null
  }

  return (
    <ArrayField {...props}>
      <SingleFieldList>
        <StringToLabelObject>
          <ChipField source="label" />
        </StringToLabelObject>
      </SingleFieldList>
    </ArrayField>
  )
}
export default ArrayChipField
