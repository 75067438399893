import React from 'react'
import LoaderLib from 'react-loader-spinner'

const Loader = ({ active, text }) =>
  active ? (
    <div
      style={{
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'rgba(128, 128, 128, 0.7)',
        zIndex: 9999,
      }}
    >
      <LoaderLib color="orange" height={50} type="TailSpin" />
      <div className="loader-text" style={{ marginTop: '20px' }}>
        {text}
      </div>
    </div>
  ) : null

export default Loader
