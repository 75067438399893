// @flow

import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  EditGuesser,
  FileInput,
  ImageField,
} from 'react-admin'

export const ConfigList = (props: any) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
      </Datagrid>
    </List>
  )
}

export const ConfigEdit = (props: any) => {
  console.log('props', props)

  if (props.id === 'config') return <EditGuesser {...props} />

  return (
    <Edit {...props}>
      <SimpleForm>
        <FileInput
          accept="image/*"
          label="Event Bild"
          source="placeholder_image_event"
        >
          <ImageField source="url" title="title" />
        </FileInput>
        <FileInput
          accept="image/*"
          label="Company Bild"
          source="placeholder_image_company"
        >
          <ImageField source="url" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}
