// LoginPage.js
import React from 'react'
import { Login, LoginForm } from 'react-admin'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import * as firebase from 'firebase/app'

// Add the Firebase services that you want to use
import 'firebase/auth'

// Configure FirebaseUI.
const uiConfig = {
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '#/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // Use email link authentication and do not require password.
      // Note this setting affects new users only.
      // For pre-existing users, they will still be prompted to provide their
      // passwords on sign-in.
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      // Allow the user the ability to complete sign-in cross device, including
      // the mobile apps specified in the ActionCodeSettings object below.
      forceSameDevice: false,
      // Used to define the optional firebase.auth.ActionCodeSettings if
      // additional state needs to be passed along request and whether to open
      // the link in a mobile app if it is installed.
      // emailLinkSignIn: function() {
      //   return {
      //     // Additional state showPromo=1234 can be retrieved from URL on
      //     // sign-in completion in signInSuccess callback by checking
      //     // window.location.href.
      //     // url: 'https://www.example.com/completeSignIn?showPromo=1234',
      //     url: window.location.href,
      //     // Custom FDL domain.
      //     // dynamicLinkDomain: 'example.page.link',
      //     // Always true for email link sign-in.
      //     handleCodeInApp: true,
      //     // Whether to handle link in iOS app if installed.
      //     // iOS: {
      //     //   bundleId: 'com.example.ios'
      //     // },
      //     // Whether to handle link in Android app if opened in an Android
      //     // device.
      //     // android: {
      //     //   packageName: 'com.example.android',
      //     //   installApp: true,
      //     //   minimumVersion: '12'
      //     // }
      //   }
      // },
    },
  ],
}

const SignInScreen = () => (
  <StyledFirebaseAuth firebaseAuth={firebase.auth()} uiConfig={uiConfig} />
)

const CustomLoginForm = props => (
  <div>
    <SignInScreen />
  </div>
)

const CustomLoginPage = props => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
)

export default CustomLoginPage
