import englishMessages from './en'

const i18nProvider = locale => {
  if (locale === 'de') {
    return import('./de').then(messages => messages.default)
  }

  // Always fallback on english
  return englishMessages
}

export default i18nProvider
