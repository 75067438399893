// @flow

import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

import {
  GENERAL_CATEGORIES,
  JOB_TYPES,
  JOB_KIND,
  GENERAL_STATES,
} from '../../constants'

const JobCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Company"
        reference="ecc_directory_companies"
        source="user_id"
      >
        <AutocompleteInput
          // optionText={choice => `${choice.name_company}`}
          // optionText="title"
          optionText="name_company"
          optionValue="id"
          source="name_company"
        />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="description" />

      <TextInput source="link" />
      {/* <TextInput source="company_name" /> */}

      <SelectInput choices={JOB_KIND} source="kind" />
      <SelectArrayInput choices={GENERAL_CATEGORIES} source="categories" />
      <SelectArrayInput choices={JOB_TYPES} source="types" />
      <SelectInput choices={GENERAL_STATES} source="state" />
    </SimpleForm>
  </Create>
)

export default JobCreate
