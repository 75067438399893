import React from 'react'
import { Redirect, withRouter } from 'react-router'

import axios from 'axios'
import Script from 'react-load-script'

import StripePaymentIban from './stripe-payment-iban'
import Loader from './../loader'

const stripeScript = 'https://js.stripe.com/v3'
const stripePublishableKey = 'pk_test_YNAzxznq2vJIFFWeaBFMh7MR00kSO7GhD6'

class StripePayment extends React.PureComponent {
  state = {
    loaded: false,
    loading: true,
    saving: false,
    error: null,
  }
  handleResult = (result, membership, reference, test) => {
    if (result.error) {
      this.setState({ error: result.error })
    } else {
      this.setState({ loading: true })
      this.sentSource(result.source, membership, reference, test)
    }
  }

  sentSource = (source, stripePlan, reference, livemode) => {
    // POST to Cloud Function to trigger charge
    axios
      .post(
        'https://europe-west1-ecc-app-eu.cloudfunctions.net/processStripeSEPA',
        {
          email: source.owner.email,
          source,
          reference: reference,
          stripePlan,
          livemode,
        },
      )
      .then(response => {
        console.log(response)
        this.props.history.push('/pay/success')
      })
      .catch(error => {
        console.log(error)
        this.props.history.push('/pay/cancel')
      })
  }

  stripeCheckoutRedirect = stripeSessionId => {
    // Initialize Stripe
    const stripe = window.Stripe(stripePublishableKey)

    stripe
      .redirectToCheckout({
        sessionId: stripeSessionId,
      })
      .then(function(result) {
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer.
          var displayError = document.getElementById('error-message')
          displayError.textContent = result.error.message
        }
      })
  }

  render() {
    const {
      method,
      data: { email, reference, stripePlan, stripeSessionId },
      livemode,
    } = this.props
    const { loading, loaded, saving } = this.state

    if (
      (method === 'credit' && !stripeSessionId) ||
      (method === 'sepa' && !email && !reference && !stripePlan)
    )
      return <Redirect to="/" />

    return (
      <div className={'stripe-payment'}>
        <Script
          onError={error => console.log('onError', error)}
          onLoad={() => this.setState({ loaded: true, loading: false })}
          url={stripeScript}
        />

        <Loader
          active={loading}
          text={
            loading
              ? 'Creating secure session'
              : saving
              ? 'Saving your payment details'
              : null
          }
        />
        {loaded && method === 'sepa' ? (
          <StripePaymentIban
            address={{ email }}
            apiKey={stripePublishableKey}
            handleResult={(stripe, result) =>
              this.handleResult(result, stripePlan, reference, livemode)
            }
          />
        ) : null}
        {loaded && method === 'credit'
          ? this.stripeCheckoutRedirect(stripeSessionId)
          : null}
      </div>
    )
  }
}

export default withRouter(StripePayment)
