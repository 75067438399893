import React from 'react'

import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  ImageField,
  SelectArrayInput,
} from 'react-admin'

import { GENERAL_CATEGORIES } from '../../constants'

const UsersEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name_first" />
      <TextInput source="name_last" />
      <TextInput source="email" />

      <TextInput source="name_company" />
      <TextInput source="description_short" />
      <TextInput source="description" />
      <SelectArrayInput
        choices={GENERAL_CATEGORIES}
        label="Branche"
        source="categories"
      />

      <SelectArrayInput
        choices={[
          { id: 'description_short', name: 'Beschreibung (kurz/slogan)' },
          { id: 'description', name: 'Beschreibung' },
          { id: 'name_first', name: 'Vorname' },
          { id: 'name_last', name: 'Nachname' },
          { id: 'name_company', name: 'Firma' },
          { id: 'user_interests', name: 'Interessen' },
          { id: 'user_status', name: 'Status' },
          { id: 'categories', name: 'Branche' },
        ]}
        source="directory"
      />

      <FileInput accept="image/*" label="Bild" source="image">
        <ImageField source="url" title="title" />
      </FileInput>
      {/* <TextInput source="id" /> */}
    </SimpleForm>
  </Edit>
)

export default UsersEdit
